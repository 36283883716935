import { paperClasses, Typography } from '@mui/material'
import { useEffect } from 'react'
import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import GoBackToTheShopButton from '~/src/components/navigation/GoBackToTheShopButton'
import Title from '~/src/components/mui-wrappers/Title'
import GoOrderStatusButton from '~/src/components/navigation/GoOrderStatusButton'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import useResetStates from '~/src/hooks/useResetStates'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CART_STATE, CartState } from '~/src/stores/cart'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import useSetFirstAvailableTime from '~/src/hooks/ordering-methods/useSetFirstAvailableTime'
import ThankYouOrderPushNotificationTips from '~/src/components/orders/ThankYouOrderPushNotificationTips'
import { DialogNameEnum } from '~/src/types/DialogNameEnum'

interface Props {
  open?: boolean,
}

const ThankYouForYourOrderResponsiveDialog = ({ open }: Props) => {
  const { t } = useTranslations()
  const firm = useEoValue(FIRM_SELECTOR)
  const { resetStates } = useResetStates()
  const setCartState = useSetEoState(CART_STATE)
  const { setFirstAvailableTime } = useSetFirstAvailableTime()

  useEffect(() => {
    if (!open) {
      return
    }

    resetStates()
    setCartState((cart: CartState) => ({
      ...cart,
      [firm!.id]: []
    }))
    void setFirstAvailableTime()
  }, [ open ])

  if (!open) {
    return null
  }

  return (
    <ResponsiveDialog
      openByDefault={open}
      name={DialogNameEnum.THANK_YOU_FOR_YOUR_ORDER}
      Title={<GoBackToTheShopButton />}
      Actions={<GoOrderStatusButton />}
      sx={{
        [`.${paperClasses.root}`]: {
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
          '& a': {
            width: '100%'
          }
        }
      }}
    >
      <Title variant='h4'>
        {t('orders.thankYou.title')}
      </Title>
      <Typography variant='body1'>
        {t('orders.thankYou.message')}
      </Typography>
      <ThankYouOrderPushNotificationTips />
    </ResponsiveDialog>
  )
}

export default ThankYouForYourOrderResponsiveDialog
