import { Typography } from '@mui/material'
import Divider from '~/src/components/mui-wrappers/Divider'
import { useEoValue } from '~/src/hooks/useEoState'
import { Customer } from '@eo-storefronts/eo-core'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import EnvUtils from '~/src/utils/EnvUtils'
import { useTranslations } from '~/src/hooks/useTranslations'
import PushNotificationSwitch from '~/src/components/PushNotificationSwitch'
import { useState } from 'react'

const ThankYouOrderPushNotificationTips = () => {
  const customer = useEoValue<Customer | null>(CUSTOMER_STATE)
  const [ allowNotification ] = useState(!!customer?.allowPushNotifications)
  const { t } = useTranslations()

  if (!customer || EnvUtils.isDeviceWeb || allowNotification) {
    return null
  }

  return (
    <>
      <Divider />
      <Typography variant='h6'>{t('orders.thankYou.tipsTitle')}</Typography>
      <Typography variant='body1'>{t('orders.thankYou.tipsMessage')}</Typography>
      <PushNotificationSwitch
        labelPlacement='start'
        sx={{
          width: '100%',
          m: 0,
          justifyContent: 'space-between'
        }}
      />
    </>
  )
}

export default ThankYouOrderPushNotificationTips
